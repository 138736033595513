import React from "react";
import NotFound from "../components/NotFound";

export default props => {
    return (
        <>
            <NotFound />
        </>
    );
};
